import React from 'react';
import './Music.css'

const Music = () => {
  return (
    <div className="App-content">
              <div className="App-textbox">
You can find Scan's music in all the usual places as well as his <a className="App-link" href="https://scandraxley.bandcamp.com">Bandcamp</a>.
<p>
This page highlights pieces that reflect the diversity of Scan's music.
</p>
        </div>
        <p></p>

<table>
<tr>
  <td>
<div className="App-textbox">
<iframe style={{border: 0, width: '350px', height: '442px'}}
src="https://bandcamp.com/EmbeddedPlayer/track=400980129/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
title="Letter 27"
seamless><a href="https://scandraxley.bandcamp.com/track/letter-27">Letter 27 by Scan Draxley</a></iframe>
Letter 27
</div>
</td>

<td>
<div className="App-textbox">
<iframe style={{border: 0, width: '350px', height: '442px'}}
src="https://bandcamp.com/EmbeddedPlayer/track=1585561177/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
title="Shore of Infinity"
seamless><a href="https://scandraxley.bandcamp.com/track/shore-of-infinity">Shore of Infinity by Scan Draxley</a></iframe>
Shore of Infinity
</div>
</td>
</tr>
<tr>

<td>
<div className="App-textbox">
<iframe style={{border: 0, width: '350px', height: '442px'}}
src="https://bandcamp.com/EmbeddedPlayer/track=4103146828/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
title="Fat Nobodys"
seamless><a href="https://scandraxley.bandcamp.com/track/fat-nobodys-in-company-cars">Fat Nobody's in Company Cars</a></iframe>
Fat Nobody's in Company Cars
</div>
</td>
<td></td>
</tr>

</table>
</div>
  );
};

export default Music;