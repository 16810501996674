import React from 'react';
import { Link } from 'react-router-dom';
import './Contact.css'

const Contact = () => {
  return (
    <div className="App-content">
        <div className="App-textbox">
Contact Scan here: -
<p>
<div onClick={(e) => {window.location.href ='mailto:scandraxley@gmail.com';}}>Email: scandraxley@gmail.com</div>
</p>
<p>
BlueSky: <Link className="App-link" to="https://bsky.app/profile/scandraxley.bsky.social">Profile</Link>
</p>
        </div>
</div>
  );
};

export default Contact;