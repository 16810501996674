// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route,Routes,Link } from 'react-router-dom';
import './App.css'
import Backdrop from './Backdrop';
import About from './About';
import Music from './Music';
import Contact from './Contact';

const App = () => {
  return (
    <Router>
      <Backdrop />
      <div className="App">
        <div className="App-overlay"></div>
        <div className="App-header">
            SCAN DRAXLEY
        </div>

        <Routes>
          <Route path="/about" Component={About} />
          <Route path="/music" Component={Music} />
          <Route path="/contact" Component={Contact} />
        </Routes>
        <div className="App-content">
              <fieldset className="App-contentbox">
                <legend className='App-title'>ABOUT</legend>
                  Scan Draxley is a UK-based composer and multi-instrumentalist renowned for his captivating post-rock compositions. Since the 1980s, he has been crafting intricate soundscapes, both independently and in collaboration with other artists.
                  His instrumental music often delves into themes of introspection, nostalgia, and the natural world, evoking a wide range of emotions through its rich textures, evocative melodies, and immersive soundscapes.
                          <p></p>
                  You can find Scan's music in all the usual places as well as his <a className="App-link" href="https://scandraxley.bandcamp.com">Bandcamp</a>.
              </fieldset>

            <fieldset className="App-contentbox">
              <legend className='App-title'>MUSIC</legend>
              <p>
                  This selection highlights pieces that reflect the diversity of Scan's music.
                  </p>
                <table>
                <tr>
                  <td>
                    <div className="App-textbox">
                      <iframe style={{border: 0, width: '350px', height: '442px'}}
                      src="https://bandcamp.com/EmbeddedPlayer/track=400980129/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
                      title="Letter 27"
                      seamless><a href="https://scandraxley.bandcamp.com/track/letter-27">Letter 27 by Scan Draxley</a></iframe>
                      <div className="App-tracktitle">Letter 27</div>
                    </div>
                  </td>

                <td>
                  <div className="App-textbox">
                    <iframe style={{border: 0, width: '350px', height: '442px'}}
                    src="https://bandcamp.com/EmbeddedPlayer/track=1585561177/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
                    title="Shore of Infinity"
                    seamless><a href="https://scandraxley.bandcamp.com/track/shore-of-infinity">Shore of Infinity by Scan Draxley</a></iframe>
                    <div className="App-tracktitle">Shore of Infinity</div>
                  </div>
                </td>
                </tr>
                <tr>

                <td>
                    <div className="App-textbox">
                    <iframe style={{border: 0, width: '350px', height: '442px'}}
                    src="https://bandcamp.com/EmbeddedPlayer/track=4103146828/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
                    title="Fat Nobodys"
                    seamless><a href="https://scandraxley.bandcamp.com/track/fat-nobodys-in-company-cars">Fat Nobody's in Company Cars</a></iframe>
                    <div className="App-tracktitle">Fat Nobody's in Company Cars</div>
                    </div>
                </td>
                <td></td>
                </tr>
                </table>
</fieldset>


    <fieldset className="App-contentbox">
      <legend className="App-title">CONTACT</legend>
      Contact Scan here: -
      <p>
        <div classNameonClick={(e) => {window.location.href ='mailto:scandraxley@gmail.com';}}>Email: <div className='App-link'>scandraxley@gmail.com</div></div>
        </p>
        <p>
        BlueSky: <div><Link className="App-link" to="https://bsky.app/profile/scandraxley.bsky.social">Profile</Link></div>
        </p>
    </fieldset>
</div>
</div>
    </Router>
  );
};

export default App;
